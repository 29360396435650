import React, { useState } from "react";
import "./dataform.css";
import kalkaLogo from "../assets/images/logo.png";
import { uri } from "../utils/constants";
import axios from "axios";

const DataForm = () => {
  const initialValues = {
    applicantId: "",
    applicantName: "",
    thulla: "",
    deptName: "",
    fatherName: "",
    contactNo: "",
    ppAvailed: "",
    ppIssued: "",
    sdAvailed: "",
    sdIssued: "",
    pfAvailed: "",
    pfIssued: "",
    parkAvailed: "",
    parkIssued: "",
    vipParkAvailed: "",
    vipParkIssued: "",
    volunteersAvailed: "",
    volunteersIssued: "",
    admOffAvailed: "",
    admOffIssued: "",
    admParkAvailed: "",
    admParkIssued: ""
  };

  const [formValues, setFormValues] = useState(initialValues);
  const [formErrors, setFormErrors] = useState({});

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormValues({ ...formValues, [name]: value });
  };

  const submitForm = async (e) => {
    try {
      e.preventDefault();
      const errorCheck = validate(formValues);
      setFormErrors(errorCheck);
      console.log(formValues, "form values");
      // return

      if (Object.keys(errorCheck).length === 0) {
        const res = await axios.post(
          `${uri}/admin/dataentry/enteredData`,
          formValues
        );
        if (res.status === 200) {
          alert("Details Submitted Successfully.");
          resetInput();
        } else {
          alert("Got Some Error!! Try Again Later!!");
        }
      }
    } catch (err) {
      // console.log(err.response);
      alert(err.response.statusText);
    }
  };

  const resetInput = () => {
    setFormValues(initialValues);
    window.location.reload(false);
  };

  const getData = async (e) => {
    e.preventDefault();
    try {
      console.log("ha send kiya ", formValues.applicantId);
      const res = await axios.post(`${uri}/admin/dataentry/getDataFromId`, {
        applicantId: formValues.applicantId
      });
      if (res.status === 200) {
        alert("Details Fetched Successfully.");
        console.log("Data fetched", res.data.data);
        setFormValues(res.data.data);
        // resetInput();
      } else {
        alert("Got Some Error!! Try Again Later!!");
      }
    } catch (err) {
      alert(err.response.statusText);
    }
  };

  const validate = (values) => {
    const errors = {};
    const regex = /^[^\s@]+@[^\s@]+\.[^\s@]{2,}$/i;

    //// pujari Id
    if (!values.applicantId) {
      errors.applicantId = "Applicant Id is Required";
    }

    //pujari name
    if (!values.applicantName) {
      errors.applicantName = "Applicant Name is Required";
    }

    if (values.contactNo) {
      if (values.contactNo.length < 10) {
        errors.contactNo = "Enter at least 10 digits";
      }
    }

    if (!values.contactNo) {
      errors.contactNo = "Contact Number is Required";
    }

    if (!values.thulla || values.thulla == "0") {
      errors.thulla = "Pease Select Valid Thulla";
    }

    return errors;
  };

  return (
    <>
      <div className="data-form">
        <div className="form-value">
          <img
            src={kalkaLogo}
            alt="kalka ji Mandir"
            width={300}
            className="data-form-logo"
          />
          <div>
            <div className="form-row">
              <div className="form-group col-md-6">
                <label>
                  1. Id Card Number
                  <span className="text-red font-weight-bold">*</span>
                </label>
                <input
                  type="text"
                  maxLength={10}
                  className="form-control"
                  placeholder="Id Card Number"
                  name="applicantId"
                  onChange={handleChange}
                  value={formValues.applicantId}
                />
                <small className="text-red">{formErrors.applicantId}</small>
              </div>
              {/* <div className="form-group btn-div w-25 col-md-6">
                <button className="get-data" onClick={getData}>
                  Get Data
                </button>
              </div> */}
            </div>
            <div className="form-row">
              <div className="form-group col-md-6">
                <label>
                  2. Name of Applicant
                  <span className="text-red font-weight-bold">*</span>
                </label>
                <input
                  type="text"
                  className="form-control upper"
                  placeholder="Name of Applicant"
                  name="applicantName"
                  onChange={handleChange}
                  value={formValues.applicantName}
                />
                <small className="text-red">{formErrors.applicantName}</small>
              </div>
            </div>
            <div className="form-row">
              <div className="form-group col-md-6">
                <label>
                  3. Thulla/Department
                  <span className="text-red font-weight-bold">*</span>
                </label>
                <select
                  className="form-control upper"
                  name="thulla"
                  onChange={handleChange}
                >
                  <option value="0" className="upper">
                    Select
                  </option>
                  <option value="Jasram Thulla" className="upper">
                    Jasram Thulla
                  </option>
                  <option value="Bahadur Thulla" className="upper">
                    Bahadur Thulla
                  </option>
                  <option value="Tansukh Thulla" className="upper">
                    Tansukh Thulla{" "}
                  </option>
                  <option value="Rambaksh Thulla" className="upper">
                    Rambaksh Thulla
                  </option>
                  <option value="Garbari Jogis" className="upper">
                    Garbari Jogis
                  </option>
                  <option value="Kanphatta Jogis" className="upper">
                    Kanphatta Jogis
                  </option>
                  <option value="Volunteers" className="upper">
                    Volunteers
                  </option>
                  <option value="Administrative" className="upper">
                    Administrative
                  </option>
                  <option value="Special Darshan" className="upper">
                    Special Darshan
                  </option>
                </select>
                <small className="text-red">{formErrors.thulla}</small>
              </div>
              <div className="form-group col-md-6">
                <label>Confirm(Edit if)</label>
                <input
                  type="text"
                  className="form-control upper"
                  placeholder="Thulla Confirmation"
                  name="deptName"
                  onChange={handleChange}
                  value={formValues.deptName || formValues.thulla}
                />
                <small className="text-red">{formErrors.deptName}</small>
              </div>
            </div>
            <div className="form-row">
              <div className="form-group col-md-6">
                <label>4. Father's/Husband's Name</label>
                <input
                  type="text"
                  className="form-control upper"
                  placeholder="Father's/Husband's Name"
                  name="fatherName"
                  onChange={handleChange}
                  value={formValues.fatherName}
                />
                <small className="text-red">{formErrors.fatherName}</small>
              </div>
            </div>
            <div className="form-row">
              <div className="form-group col-md-6">
                <label>
                  5. Contact Number
                  <span className="text-red font-weight-bold">*</span>
                </label>
                <input
                  type="text"
                  maxLength={10}
                  className="form-control upper"
                  placeholder="Contact No"
                  name="contactNo"
                  onChange={handleChange}
                  value={formValues.contactNo}
                />
                <small className="text-red">{formErrors.contactNo}</small>
              </div>
            </div>

            <div className="change-clr">
              {(!formValues.thulla == "" || !formValues.thulla == "0") && (
                <div className="form-row">
                  <div className="headers1">
                    <h5>Pass Details</h5>
                  </div>
                  <div className="headers">
                    <h5>No. of Passes Availed</h5>
                  </div>
                  <div className="headers">
                    <h5>Serial Number of Passes Issued</h5>
                  </div>
                </div>
              )}

              {(formValues.thulla == "Jasram Thulla" ||
                formValues.thulla == "Bahadur Thulla" ||
                formValues.thulla == "Tansukh Thulla" ||
                formValues.thulla == "Rambaksh Thulla" ||
                formValues.thulla == "Garbari Jogis" ||
                formValues.thulla == "Kanphatta Jogis" || formValues.thulla == "Administrative") && (
                <PujariPass
                  handleChange={handleChange}
                  formValues={formValues}
                  formErrors={formErrors}
                />
              )}

              {(formValues.thulla == "Jasram Thulla" ||
                formValues.thulla == "Bahadur Thulla" ||
                formValues.thulla == "Tansukh Thulla" ||
                formValues.thulla == "Rambaksh Thulla" ||
                formValues.thulla == "Garbari Jogis" ||
                formValues.thulla == "Kanphatta Jogis" ||
                formValues.thulla == "Administrative" ||
                formValues.thulla == "Special Darshan") && (
                <SpecialDarshan
                  handleChange={handleChange}
                  formValues={formValues}
                  formErrors={formErrors}
                />
              )}
              {(formValues.thulla == "Jasram Thulla" ||
                formValues.thulla == "Bahadur Thulla" ||
                formValues.thulla == "Tansukh Thulla" ||
                formValues.thulla == "Rambaksh Thulla" ||
                formValues.thulla == "Garbari Jogis" ||
                formValues.thulla == "Kanphatta Jogis") && (
                <PujariFamily
                  handleChange={handleChange}
                  formValues={formValues}
                  formErrors={formErrors}
                />
              )}

              {(formValues.thulla == "Jasram Thulla" ||
                formValues.thulla == "Bahadur Thulla" ||
                formValues.thulla == "Tansukh Thulla" ||
                formValues.thulla == "Rambaksh Thulla" ||
                formValues.thulla == "Garbari Jogis" ||
                formValues.thulla == "Kanphatta Jogis" ||
                formValues.thulla == "Administrative" ||
                formValues.thulla == "Special Darshan") && (
                <Parking
                  handleChange={handleChange}
                  formValues={formValues}
                  formErrors={formErrors}
                />
              )}

              {(formValues.thulla == "Administrative" ||
                formValues.thulla == "Special Darshan") && (
                <VipParking
                  handleChange={handleChange}
                  formValues={formValues}
                  formErrors={formErrors}
                />
              )}

              {formValues.thulla == "Volunteers" && (
                <Volunteer
                  handleChange={handleChange}
                  formValues={formValues}
                  formErrors={formErrors}
                />
              )}

              {formValues.thulla == "Administrative" && (
                <AdminOffice
                  handleChange={handleChange}
                  formValues={formValues}
                  formErrors={formErrors}
                />
              )}

              {(formValues.thulla == "Administrative" ||
                formValues.thulla == "Special Darshan") && (
                <AdminParking
                  handleChange={handleChange}
                  formValues={formValues}
                  formErrors={formErrors}
                />
              )}
            </div>
            <div className="form-row">
              <button type="submit" className="submitForm" onClick={submitForm}>
                Submit
              </button>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default DataForm;

export const PujariPass = ({ handleChange, formValues, formErrors }) => {
  return (
    <>
      <div className="form-row">
        <div className="form-group headers1 col-md-6">
          <input
            type="text"
            readOnly={true}
            className="form-control blackCl oran-bg"
            placeholder="Pujari Pass(1 only)"
            name="pujaripass"
          />
        </div>

        <div className="form-group headers col-md-6">
          <input
            type="text"
            className="form-control"
            placeholder="Passes Availed"
            name="ppAvailed"
            maxLength={1}
            onChange={handleChange}
            value={formValues.ppAvailed}
          />
          <small className="text-red">{formErrors.ppAvailed}</small>
        </div>
        <div className="form-group headers col-md-6">
          <input
            type="text"
            className="form-control"
            placeholder="Passes Issued"
            name="ppIssued"
            // maxLength={2}
            onChange={handleChange}
            value={formValues.ppIssued}
          />
          <small className="text-red">{formErrors.ppIssued}</small>
        </div>
      </div>
    </>
  );
};

export const SpecialDarshan = ({ handleChange, formValues, formErrors }) => {
  return (
    <>
      <div className="form-row">
        <div className="form-group headers1 col-md-6">
          <input
            type="text"
            readOnly={true}
            className="form-control blackCl special_darshan"
            placeholder="Special Darshan (Max. 10)"
            name="Special Darshan"
          />
        </div>

        <div className="form-group headers col-md-6">
          <input
            type="text"
            className="form-control"
            placeholder="Passes Availed"
            name="sdAvailed"
            maxLength={3}
            onChange={handleChange}
            value={formValues.sdAvailed}
          />
          <small className="text-red">{formErrors.sdAvailed}</small>
        </div>
        <div className="form-group headers col-md-6">
          <input
            type="text"
            className="form-control"
            placeholder="Passes Issued"
            name="sdIssued"
            // maxLength={2}
            onChange={handleChange}
            value={formValues.sdIssued}
          />
          <small className="text-red">{formErrors.sdIssued}</small>
        </div>
      </div>
    </>
  );
};

export const PujariFamily = ({ handleChange, formValues, formErrors }) => {
  return (
    <>
      <div className="form-row">
        <div className="form-group headers1 col-md-6">
          <input
            type="text"
            readOnly={true}
            className="form-control blackCl pujari_family"
            placeholder="Pujari Family Pass"
            name="Pujari Family Pass"
          />
        </div>

        <div className="form-group headers col-md-6">
          <input
            type="text"
            className="form-control"
            placeholder="Passes Availed"
            name="pfAvailed"
            maxLength={3}
            onChange={handleChange}
            value={formValues.pfAvailed}
          />
          <small className="text-red">{formErrors.pfAvailed}</small>
        </div>
        <div className="form-group headers col-md-6">
          <input
            type="text"
            className="form-control"
            placeholder="Passes Issued"
            name="pfIssued"
            // maxLength={2}
            onChange={handleChange}
            value={formValues.pfIssued}
          />
          <small className="text-red">{formErrors.pfIssued}</small>
        </div>
      </div>
    </>
  );
};

export const Parking = ({ handleChange, formValues, formErrors }) => {
  return (
    <>
      <div className="form-row">
        <div className="form-group headers1 col-md-6">
          <input
            type="text"
            readOnly={true}
            className="form-control blackCl car_parking"
            placeholder="Parking Pass"
            name="Car/Bike Parking Pass"
          />
        </div>

        <div className="form-group headers col-md-6">
          <input
            type="text"
            className="form-control"
            placeholder="Passes Availed"
            name="parkAvailed"
            maxLength={3}
            onChange={handleChange}
            value={formValues.parkAvailed}
          />
          <small className="text-red">{formErrors.parkAvailed}</small>
        </div>
        <div className="form-group headers col-md-6">
          <input
            type="text"
            className="form-control"
            placeholder="Passes Issued"
            name="parkIssued"
            // maxLength={2}
            onChange={handleChange}
            value={formValues.parkIssued}
          />
          <small className="text-red">{formErrors.parkIssued}</small>
        </div>
      </div>
    </>
  );
};

export const VipParking = ({ handleChange, formValues, formErrors }) => {
  return (
    <>
      <div className="form-row">
        <div className="form-group headers1 col-md-6">
          <input
            type="text"
            readOnly={true}
            className="form-control blackCl vip_car_parking"
            placeholder="Vip Car Pass"
            name="pujaripass"
          />
        </div>

        <div className="form-group headers col-md-6">
          <input
            type="text"
            className="form-control"
            placeholder="Passes Availed"
            name="vipParkAvailed"
            maxLength={3}
            onChange={handleChange}
            value={formValues.vipParkAvailed}
          />
          <small className="text-red">{formErrors.vipParkAvailed}</small>
        </div>
        <div className="form-group headers col-md-6">
          <input
            type="text"
            className="form-control"
            placeholder="Passes Issued"
            name="vipParkIssued"
            // maxLength={2}
            onChange={handleChange}
            value={formValues.vipParkIssued}
          />
          <small className="text-red">{formErrors.vipParkIssued}</small>
        </div>
      </div>
    </>
  );
};

export const Volunteer = ({ handleChange, formValues, formErrors }) => {
  return (
    <>
      <div className="form-row">
        <div className="form-group headers1 col-md-6">
          <input
            type="text"
            readOnly={true}
            className="form-control blackCl volunteer_pass"
            placeholder="Volunteer Pass"
            name="Special Darshan"
          />
        </div>

        <div className="form-group headers col-md-6">
          <input
            type="text"
            className="form-control"
            placeholder="Passes Availed"
            name="volunteersAvailed"
            maxLength={3}
            onChange={handleChange}
            value={formValues.volunteersAvailed}
          />
          <small className="text-red">{formErrors.volunteersAvailed}</small>
        </div>
        <div className="form-group headers col-md-6">
          <input
            type="text"
            className="form-control"
            placeholder="Passes Issued"
            name="volunteersIssued"
            // maxLength={2}
            onChange={handleChange}
            value={formValues.volunteersIssued}
          />
          <small className="text-red">{formErrors.volunteersIssued}</small>
        </div>
      </div>
    </>
  );
};

export const AdminOffice = ({ handleChange, formValues, formErrors }) => {
  return (
    <>
      <div className="form-row">
        <div className="form-group headers1 col-md-6">
          <input
            type="text"
            readOnly={true}
            className="form-control blackCl admin-office"
            placeholder="Admin Office Pass"
            name="Pujari Family Pass"
          />
        </div>

        <div className="form-group headers col-md-6">
          <input
            type="text"
            className="form-control"
            placeholder="Passes Availed"
            name="admOffAvailed"
            maxLength={3}
            onChange={handleChange}
            value={formValues.admOffAvailed}
          />
          <small className="text-red">{formErrors.admOffAvailed}</small>
        </div>
        <div className="form-group headers col-md-6">
          <input
            type="text"
            className="form-control"
            placeholder="Passes Issued"
            name="admOffIssued"
            // maxLength={2}
            onChange={handleChange}
            value={formValues.admOffIssued}
          />
          <small className="text-red">{formErrors.admOffIssued}</small>
        </div>
      </div>
    </>
  );
};

export const AdminParking = ({ handleChange, formValues, formErrors }) => {
  return (
    <>
      <div className="form-row">
        <div className="form-group headers1 col-md-6">
          <input
            type="text"
            readOnly={true}
            className="form-control blackCl admin-parking"
            placeholder="Admin Parking Pass"
            name="Parking Pass"
          />
        </div>

        <div className="form-group headers col-md-6">
          <input
            type="text"
            className="form-control"
            placeholder="Passes Availed"
            name="admParkAvailed"
            maxLength={3}
            onChange={handleChange}
            value={formValues.admParkAvailed}
          />
          <small className="text-red">{formErrors.admParkAvailed}</small>
        </div>
        <div className="form-group headers col-md-6">
          <input
            type="text"
            className="form-control"
            placeholder="Passes Issued"
            name="admParkIssued"
            // maxLength={2}
            onChange={handleChange}
            value={formValues.admParkIssued}
          />
          <small className="text-red">{formErrors.admParkIssued}</small>
        </div>
      </div>
    </>
  );
};
