import React from 'react'
import Logo from '../src/assets/images/ggpqr.png'

const Piggy = () => {
  return (
    <>
      <div className='piggy-home'>
        <img src={Logo} height={200} width={200}/>
        <h1>Download the App Now!!</h1>
        <h2>Good Good Piggy</h2>

        <h3>Kalkaji QR Pass Service provided by, </h3>
        <h3>FinKids Innovations Private Limited</h3>
      </div>
    </>
  )
}

export default Piggy