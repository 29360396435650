import React from "react";
// import "./App.css";
import MainWrapper from "./Pass_view/MainWrapper";
import Pass from "./VehiclePass";
import Test from "./Test";
import Home from "./Home";
import Piggy from "./Piggy";
import DataForm from "./pages/DataForm";
import GetData from "./pages/GetData";
import { BrowserRouter, Routes, Route } from "react-router-dom";

const App = () => {
  return (
    <>
      <BrowserRouter>
        <Routes>
          <Route element={<Home />} path="/" exact="true" />
          <Route element={<Piggy />} path="/piggy" exact="true" />
          <Route element={<DataForm />} path="/passApplication" exact="true" />
          <Route element={<GetData />} path="/getAuth" exact="true" />

          <Route
            element={<MainWrapper />}
            path="/getPassDetails?"
            exact="true"
          />
          {/* <Route element={<Test />} path="/generatePass" exact="true" /> */}
          {/* <Route element={<Pass />} path="/activetepass/" exact="true" /> */}
        </Routes>
      </BrowserRouter>
    </>
  );
};

export default App;
