const uri = "https://api.kalkajitemple.com/v1";
// const uri = "http://localhost:4055/v1";

module.exports = {
  uri,
};


// className={`new-sec ${
//   passData?.qrType === "PP" ? "oran-bg" : ""
// } ${passData?.qrType === "PFP" ? "pujari_family" : ""} ${
//   passData?.qrType === "SDP" ? "special_darshan" : ""
// } ${passData?.qrType === "VP" ? "volunteer_pass" : ""} ${
//   passData?.qrType === "P-VIP" ? "vip_car_parking" : ""
// } ${passData?.qrType === "P-NP" ? "car_parking" : ""} ${
//   passData?.qrType === "AOP" ? "admin-office" : ""
// } ${passData?.qrType === "P-AP" ? "admin-parking" : ""}`}