import React, { useEffect, useState } from "react";
import "./pass.css";
import QRCode from "react-qr-code";

export default function PassView({ data }) {
  console.log(data?.qrId, "data render");
  const [passData, setPassData] = useState(data);

  // console.log(passData?.qrId);

  useEffect(() => {
    setPassData(data);
  }, [data]);

  return (
    <center className="wrapper">
      <table className="pass-main" width="100%">
        {/* BORDER */}
        <tbody>
          <tr>
            <td
              style={{ height: "115px" }}
              className={`new-sec ${
                passData?.qrType === "PP" ? "oran-bg" : ""
              } ${passData?.qrType === "PFP" ? "pujari_family" : ""} ${
                passData?.qrType === "SDP" ? "special_darshan" : ""
              } ${passData?.qrType === "VP" ? "volunteer_pass" : ""} ${
                passData?.qrType === "P-VIP" ? "vip_car_parking" : ""
              } ${passData?.qrType === "P-NP" ? "car_parking" : ""} ${
                passData?.qrType === "AOP" ? "admin-office" : ""
              } ${passData?.qrType === "P-AP" ? "admin-parking" : ""}`}
            >
              <h2 style={{ fontWeight: 600 }}>
                Office of the Ld. Administrator
              </h2>
              <h3 style={{ margin: "0", fontWeight: 500 }}>
                Appointed by Hon’ble High Court of Delhi
              </h3>
              <h1>Kalkaji Mandir</h1>
            </td>
          </tr>
          <tr>
            <td style={{ height: "60px" }} className="new-sec">
              <h1
                style={{ fontWeight: 600, fontSize: "18px" }}
                className={`${
                  passData?.qrType === "PP"
                    ? "oran-bg-cl"
                    : passData?.qrType === "PFP"
                    ? "pujari_family-cl"
                    : passData?.qrType === "SDP"
                    ? "special_darshan-cl"
                    : passData?.qrType === "VP"
                    ? "volunteer_pass-cl"
                    : passData?.qrType === "P-VIP"
                    ? "vip_car_parking-cl"
                    : passData?.qrType === "P-NP"
                    ? "car_parking-cl"
                    : passData?.qrType === "AOP"
                    ? "admin-office-cl"
                    : passData?.qrType === "P-AP"
                    ? "admin-parking-cl" : ""
                }`}
              >
                {passData?.qrType === "PP"
                  ? "PUJARI PASS"
                  : passData?.qrType === "PFP"
                  ? "PUJARI FAMILY PASS"
                  : passData?.qrType === "SDP"
                  ? "SPECIAL DARSHAN PASS"
                  : passData?.qrType === "VP"
                  ? "VOLUNTEER PASS"
                  : passData?.qrType === "P-VIP"
                  ? "VIP CAR PARKING PASS"
                  : passData?.qrType === "P-NP"
                  ? "CAR/BIKE PASS"
                  : passData?.qrType === "AOP"
                  ? "ADMIN OFFICE PASS"
                  : passData?.qrType === "P-AP"
                  ? "ADMIN PARKING PASS" : ""}{" "}
                ({passData?.qrId})
              </h1>
            </td>
          </tr>
          <tr>
            <td
              style={{ height: "100px" }}
              className={`new-sec ${
                passData?.qrType === "PP" ? "oran-bg" : ""
              } ${passData?.qrType === "PFP" ? "pujari_family" : ""} ${
                passData?.qrType === "SDP" ? "special_darshan" : ""
              } ${passData?.qrType === "VP" ? "volunteer_pass" : ""} ${
                passData?.qrType === "P-VIP" ? "vip_car_parking" : ""
              } ${passData?.qrType === "P-NP" ? "car_parking" : ""} ${
                passData?.qrType === "AOP" ? "admin-office" : ""
              } ${passData?.qrType === "P-AP" ? "admin-parking" : ""}`}
            >
              <h4 style={{ fontWeight: 600, fontSize: "18px" }}>
                VALID FOR NAVRATRAS
              </h4>
              <h4 style={{ fontWeight: 600, fontSize: "18px" }}>
                9<sup>th</sup> April 2024 TO 17<sup>th</sup> April 2024
              </h4>
            </td>
          </tr>
          <tr>
            <td
              className="new-sec"
              style={{
                height: "178px",
                display: "flex",
                justifyContent: "center",
                alignItems: "center"
              }}
            >
              <QRCode
                size={158}
                value={`https://kalkajitemple.com/activetepass?type=${passData.qrType}&passId=${passData?.qrString}`}
              />

              {/* <QRCode
                size={158}
                value={`http://192.168.1.14:3000/activetepass?type=${passData.qrType}&passId=${passData?.qrString}`}
              /> */}
            </td>
          </tr>
          {/* <tr>
            <td className="oran-bg">
              <h2 style={{ fontWeight: 600, fontSize: "13px" }}>
                {passData?.qrId}
              </h2>
            </td>
          </tr> */}
          <tr>
            <td
              className="new-sec"
              style={{
                display: "flex",
                justifyContent: "center",
                padding: "2px 20px 2px"
              }}
            >
              <table width="100%">
                <tbody>
                  <tr>
                    <td
                      className={`new-sec ${
                        passData?.qrType === "PP" ? "oran-bg" : ""
                      } ${passData?.qrType === "PFP" ? "pujari_family" : ""} ${
                        passData?.qrType === "SDP" ? "special_darshan" : ""
                      } ${passData?.qrType === "VP" ? "volunteer_pass" : ""} ${
                        passData?.qrType === "P-VIP" ? "vip_car_parking" : ""
                      } ${passData?.qrType === "P-NP" ? "car_parking" : ""} ${
                        passData?.qrType === "AOP" ? "admin-office" : ""
                      } ${passData?.qrType === "P-AP" ? "admin-parking" : ""}`}
                      style={{
                        borderRadius: "9px",
                        padding: "4px 4px 0",
                        marginBottom: "0px",
                        height: "137px"
                      }}
                    >
                      <h3
                        style={{
                          fontWeight: 600,
                          // textAlign: "left",
                          fontSize: "17px"
                        }}
                      >
                        STEP 1: SCAN QR CODE TO ACTIVATE PASS
                      </h3>
                      <h3
                        style={{
                          fontWeight: 600,
                          // textAlign: "left",
                          fontSize: "18px"
                        }}
                      >
                        STEP 2: SCAN QR CODE DURING ENTRY
                      </h3>
                      {/* {passData?.qrType === "AOP" ? (
                        <h2 style={{ fontWeight: 600, fontSize: "18px" }}>
                          ENTRY GATE - ALLOTED
                        </h2>
                      ) : ( */}
                        {/* <> */}
                          <h2 style={{ fontWeight: 600, fontSize: "18px" }}>
                            VIP GATE - RAM PIYAO PARKING
                          </h2>
                          <h2 style={{ fontWeight: 600, fontSize: "18px" }}>
                            (Adjoining Kalkaji Metro Station)
                          </h2>
                        {/* </> */}
                      {/* )} */}
                    </td>
                  </tr>
                  <tr>
                    <td
                      className="new-bg"
                      style={{
                        height: "60px"
                      }}
                    >
                      <h3
                        style={{ fontWeight: 600, fontSize: "17px" }}
                        className={`${
                          passData?.qrType === "PP"
                            ? "oran-bg-cl"
                            : passData?.qrType === "PFP"
                            ? "pujari_family-cl"
                            : passData?.qrType === "SDP"
                            ? "special_darshan-cl"
                            : passData?.qrType === "VP"
                            ? "volunteer_pass-cl"
                            : passData?.qrType === "P-VIP"
                            ? "vip_car_parking-cl"
                            : passData?.qrType === "P-NP"
                            ? "car_parking-cl"
                            : passData?.qrType === "AOP"
                            ? "admin-office-cl"
                            : passData?.qrType === "P-AP"
                            ? "admin-parking-cl" : ""
                        }`}
                      >
                        {/* PUJARI ID REQUIRED */}
                        {passData?.qrType === "PP"
                          ? "PUJARI ID REQUIRED"
                          : passData?.qrType === "PFP"
                          ? "GOVERNMENT ID REQUIRED"
                          : passData?.qrType === "SDP"
                          ? "GOVERNMENT ID REQUIRED"
                          : passData?.qrType === "VP"
                          ? "GOVERNMENT ID REQUIRED"
                          : passData?.qrType === "P-VIP"
                          ? "GOVERNMENT ID REQUIRED"
                          : passData?.qrType === "P-NP"
                          ? "GOVERNMENT ID REQUIRED"
                          : passData?.qrType === "AOP"
                          ? "GOVERNMENT ID REQUIRED"
                          : passData?.qrType === "P-AP"
                          ? "GOVERNMENT ID REQUIRED" : ""}
                      </h3>
                      <h3
                        style={{ fontWeight: 600, fontSize: "17px" }}
                        className={`${
                          passData?.qrType === "PP"
                            ? "oran-bg-cl"
                            : passData?.qrType === "PFP"
                            ? "pujari_family-cl"
                            : passData?.qrType === "SDP"
                            ? "special_darshan-cl"
                            : passData?.qrType === "VP"
                            ? "volunteer_pass-cl"
                            : passData?.qrType === "P-VIP"
                            ? "vip_car_parking-cl"
                            : passData?.qrType === "P-NP"
                            ? "car_parking-cl"
                            : passData?.qrType === "AOP"
                            ? "admin-office-cl"
                            : passData?.qrType === "P-AP"
                            ? "admin-parking-cl" : ""
                        }`}
                      >
                        FOR ENTRY VERIFICATION
                      </h3>
                    </td>
                  </tr>
                </tbody>
              </table>
            </td>
          </tr>
          <tr>
            <td
              style={{
                height: "100px"
              }}
              className={`new-sec ${
                passData?.qrType === "PP" ? "oran-bg" : ""
              } ${passData?.qrType === "PFP" ? "pujari_family" : ""} ${
                passData?.qrType === "SDP" ? "special_darshan" : ""
              } ${passData?.qrType === "VP" ? "volunteer_pass" : ""} ${
                passData?.qrType === "P-VIP" ? "vip_car_parking" : ""
              } ${passData?.qrType === "P-NP" ? "car_parking" : ""} ${
                passData?.qrType === "AOP" ? "admin-office" : ""
              } ${passData?.qrType === "P-AP" ? "admin-parking" : ""}`}
            >
              <h2 style={{ fontWeight: 600, fontSize: "17px" }}>
                VALID FOR SINGLE PERSON ONLY
              </h2>
              <h2 style={{ fontWeight: 600, fontSize: "17px" }}>
                <u>THIS PASS IS NOT FOR SALE</u>
              </h2>
              <h3 style={{ fontWeight: 600, fontSize: "17px" }}>
                ONLY ORIGINAL PASS IS VALID
              </h3>
            </td>
          </tr>
        </tbody>
      </table>
    </center>
  );
}
