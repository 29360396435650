import React from "react";
import './App.css'
import Logo from "../src/assets/images/logo.png";

const Home = () => {
  return (
    <>
      <div className="home-page">
        <h1>Happy Navratri</h1>
        <h1>KalkaJi Mandir (2024)</h1>

        <image
          src="https://cdn.pixabay.com/photo/2016/07/07/16/46/dice-1502706_640.jpg"
          width={200}
          height={200}
          alt="kalkaLogo"
        />
      </div>
    </>
  );
};

export default Home;
