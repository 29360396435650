import React, { useEffect, useState } from "react";
import axios from "axios";
import { uri } from "../utils/constants";
import PassView from "./PassView";
import { RiArrowLeftSLine, RiArrowRightSLine } from "react-icons/ri";

export default function MainWrapper() {
  const [data, setData] = useState([]);
  const [qrString, setQRString] = useState("");
  const [page, setPage] = useState(3);
  const [total, setTotal] = useState(0);
  const [totalPages, setTotalPages] = useState(0);

  const fetchPassData = async () => {
    try {
      console.log("fetch api");
      const res = await axios.post(
        `${uri}/admin/pass/getPass?limit=1800&skip=${13590}`,
        {
          qrType: "SDP"
        }
      );
      // const res = await axios.post(`${uri}/admin/pass/getPass?`, {
      //   qrType: "SDP",
      // });
      console.log(res?.data.total, "data of pass");

      if (res.status === 200) {
        setData(res?.data?.data);
        // setTotal(res.data.total)
        console.log(res?.data.total, "total prod", typeof res?.data.total);
        // const paged = Math.floor(setTotalPages(Math.ceil(res?.data.total / 54)));
        const paged = setTotalPages(Math.floor(res?.data.total / 54));

        console.log(Math.floor(res?.data.total / 54), "ceil paged", paged);
      }
    } catch (err) {
      console.log(err.response, "no pass");
    }
  };

  const selectPageHandler = (selectedPage) => {
    console.log(selectedPage, "selectedPage", totalPages);
    if (selectedPage > 0 && selectedPage <= totalPages) {
      console.log("run from fn");
      setPage(selectedPage);
    }
  };

  useEffect(() => {
    fetchPassData();
  }, []);

  return (
    <>
      {/* {data.length > 0 && (
        <div className="order-pagination">
          <span
            onClick={() => selectPageHandler(page - 1)}
            className={page > 1 ? "" : "pagination__disable"}
          >
            <RiArrowLeftSLine className="pagination-button" />
          </span>
          {[...Array(totalPages)]?.map((_, i) => {
            return (
              <span
                className={
                  page === i + 1 ? "pagination__selected" : "pagination_no"
                }
                onClick={() => selectPageHandler(i + 1)}
                key={i}
              >
                {i + 1}
              </span>
            );
          })}
          <span
            onClick={() => selectPageHandler(page + 1)}
            className={page < totalPages ? "" : "pagination__disable"}
          >
            <RiArrowRightSLine className="pagination-button" />
          </span>
        </div>
      )} */}
      <div style={{ display: "flex", "flex-wrap": "wrap" }}>
        {data &&
          data.map((item, index) => {
            return (
              <div
                key={index}
                style={{
                  margin: 8,
                  padding: 0.5,
                  // border: "2px dashed",
                  pageBreakAfter: "always",
                  height: "765px",
                }}
              >
                <PassView data={item} />
              </div>
            );
          })}
      </div>
    </>
  );
}
